import React from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'

import Heading from '@react/react-spectrum/Heading'
import Well from '@react/react-spectrum/Well'
import { error } from '@react/react-spectrum/Toast'

import Checkbox from '@react/react-spectrum/Checkbox'
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger'
import Tooltip from '@react/react-spectrum/Tooltip'
import FieldLabel from '@react/react-spectrum/FieldLabel'
import Textfield from '@react/react-spectrum/Textfield'

export default function SenseiParams (props) {
    function toggleSelectSubjet () {
        const selectSubject = !props.params.selectSubject
        props.setParams({ ...props.params, selectSubject: selectSubject })
    }
    function toggleSelectSky () {
        const selectSky = !props.params.selectSky
        props.setParams({ ...props.params, selectSky: selectSky })
    }
    function toggleSelectSkin () {
        const selectSkin = !props.params.selectSkin
        props.setParams({ ...props.params, selectSkin: selectSkin })
    }
    function toggleSelectPeople () {
        const selectPeople = !props.params.selectPeople
        props.setParams({ ...props.params, selectPeople: selectPeople })
    }
    function toggleSelectLandscape () {
        const selectLandscape = !props.params.selectLandscape
        props.setParams({ ...props.params, selectLandscape: selectLandscape })
    }
    function toggledepthMap () {
        const depthMap = !props.params.depthMap
        props.setParams({ ...props.params, depthMap: depthMap })
    }
    function toggledepthRefinement () {
        const depthRefinement = !props.params.depthRefinement
        props.setParams({ ...props.params, depthRefinement: depthRefinement })
    }
    function setText (name, value) {
        const slider = {}
        slider[name] = value
        props.setParams({ ...props.params, ...slider })
    }

    try {
        return <div className="adobe-skycity-output-options">
            <Well>
                <Heading variant="pageTitle">{__('skycity_comp_title_output_options')}</Heading>
                <React.Fragment>
                    <Heading className="adobe-skycity-subtitle1" variant="subtitle1">Sensei Params</Heading>
                    <Well>
                        <div className="adobe-skycity-options-col">
                            <div className="adobe-skycity-acrmasking-option">
                                <OverlayTrigger placement="right">
                                    <Checkbox value={props.params.selectSubject}
                                        defaultChecked={props.params.selectSubject}
                                        onChange={ e => toggleSelectSubjet()}
                                        label={__('output_option_tooltip_selectsubject_label_acrmasking')}>
                                    </Checkbox>
                                    <Tooltip variant="info">
                                        {__('output_option_tooltip_selectsubject_label_acrmasking')}
                                    </Tooltip>
                                </OverlayTrigger>
                            </div>
                            <div className="adobe-skycity-acrmasking-option">
                                <OverlayTrigger placement="right">
                                    <Checkbox value={props.params.selectSky}
                                        onChange={ e => toggleSelectSky()}
                                        label={__('output_option_tooltip_selectsky_label_acrmasking')}>
                                    </Checkbox>
                                    <Tooltip variant="info">
                                        {__('output_option_tooltip_selectsky_label_acrmasking')}
                                    </Tooltip>
                                </OverlayTrigger>
                            </div>
                            <div className="adobe-skycity-acrmasking-option">
                                <OverlayTrigger placement="right">
                                    <Checkbox value={props.params.selectSkin}
                                        onChange={ e => toggleSelectSkin()}
                                        label={__('output_option_tooltip_selectskin_label_acrmasking')}>
                                    </Checkbox>
                                    <Tooltip variant="info">
                                        {__('output_option_tooltip_selectskin_label_acrmasking')}
                                    </Tooltip>
                                </OverlayTrigger>
                            </div>
                            <div className="adobe-skycity-acrmasking-option">
                                <OverlayTrigger placement="right">
                                    <Checkbox value={props.params.selectPeople}
                                        onChange={ e => toggleSelectPeople()}
                                        label={__('output_option_tooltip_selectpeople_label_acrmasking')}>
                                    </Checkbox>
                                    <Tooltip variant="info">
                                        {__('output_option_tooltip_selectpeople_label_acrmasking')}
                                    </Tooltip>
                                </OverlayTrigger>
                            </div>
                            <div className="adobe-skycity-acrmasking-option">
                                <OverlayTrigger placement="right">
                                    <Checkbox value={props.params.selectLandscape}
                                        onChange={ e => toggleSelectLandscape()}
                                        label={__('output_option_tooltip_selectlandscape_label_acrmasking')}>
                                    </Checkbox>
                                    <Tooltip variant="info">
                                        {__('output_option_tooltip_selectlandscape_label_acrmasking')}
                                    </Tooltip>
                                </OverlayTrigger>
                            </div>
                            <div className="adobe-skycity-acrmasking-option">
                                <OverlayTrigger placement="right">
                                    <Checkbox value={props.params.depthMap}
                                        onChange={ e => toggledepthMap()}
                                        label={__('output_option_tooltip_depthmap_label_acrmasking')}>
                                    </Checkbox>
                                    <Tooltip variant="info">
                                        {__('output_option_tooltip_depthmap_acrmasking')}
                                    </Tooltip>
                                </OverlayTrigger>
                            </div>
                            <div className="adobe-skycity-acrmasking-option">
                                <OverlayTrigger placement="right">
                                    <Checkbox value={props.params.depthRefinement}
                                        onChange={ e => toggledepthRefinement()}
                                        label={__('output_option_tooltip_depthrefinement_label_acrmasking')}>
                                    </Checkbox>
                                    <Tooltip variant="info">
                                        {__('output_option_tooltip_depthrefinement_acrmasking')}
                                    </Tooltip>
                                </OverlayTrigger>
                            </div>
                            <div className="adobe-skycity-acrmasking-option">
                                <FieldLabel className="adobe-skycity-options-field" label="Near focus" labelFor="idNearFocus" position="left">
                                    <OverlayTrigger placement="right">
                                        <Textfield
                                            label="Near focus"
                                            defaultValue={0}
                                            value={props.params.nearFocus}
                                            onChange={e => setText('nearFocus', e)}
                                            id="idNearFocus"
                                        />
                                        <Tooltip variant="info">
                                            {__('output_option_tooltip_near_focus_acrmasking')}
                                        </Tooltip>
                                    </OverlayTrigger>
                                </FieldLabel>
                            </div>
                            <div className="adobe-skycity-acrmasking-option">
                                <FieldLabel className="adobe-skycity-options-field" label="Far focus" labelFor="idFarFocus" position="left">
                                    <OverlayTrigger placement="right">
                                        <Textfield
                                            label="Far focus"
                                            defaultValue={0}
                                            value={props.params.farFocus}
                                            onChange={e => setText('farFocus', e)}
                                            id="idFarFocus"
                                        />
                                        <Tooltip variant="info">
                                            {__('output_option_tooltip_far_focus_acrmasking')}
                                        </Tooltip>
                                    </OverlayTrigger>
                                </FieldLabel>
                            </div>
                        </div>
                    </Well>
                </React.Fragment>
            </Well>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
