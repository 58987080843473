import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import {
    getFilename,
    getFilenameWithoutExt,
    getEncodedURI,
    getTempDirectory,
    getSenseiHeaders
} from '../../lib/skycity.js'

import axios from 'axios'
import {
    exploreIndexDocument,
    exploreHomeDirectory,
    uploadFile,
    retrieveRendition,
    retrievePreSignedDownloadUrl
} from '../../lib/acpApi.js'
const LinkHeader = require('http-link-header')
const uuid = require('uuid/v4')

export default function Operation (files) {
    const {
        inputFiles,
        inputSecondFiles,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)

    async function start (curFile, curSecondFile) {
        const files = []
        //  replace with presignedUrl

        const codePayload = {
            contentAnalyzerRequests: {
                'sensei:name': 'Adaptive Profiles via SkyCity',
                'sensei:invocation_mode': 'asynchronous',
                'sensei:invocation_batch': false,
                'sensei:in_response': false,
                'sensei:engines': [
                    {
                        'sensei:execution_info': {
                            'sensei:engine': 'Classification:autocrop:Service-c3e732d19f0a41dcb539d889053fbb6b'
                        },
                        'sensei:params': {
                            "output_type": "image",
                        },
                        'sensei:inputs': {
                            'image_in': {
                                'dc:format': 'application/octet-stream',
                                'sensei:repoType': 'HTTP',
                                'repo:path': curFile.path
                            },
                            'xmp_in': {
                                'dc:format': 'application/rdf+xml',
                                'sensei:repoType': 'HTTP',
                                'repo:path': curSecondFile.path
                            }
                        },
                        'sensei:outputs': {
                            'image_out': {
                                'dc:format': "image/jpeg",
                                'sensei:multipart_field_name': 'image_out'
                            },
                            'metadata_out': {
                                'dc:format': 'application/json',
                                'sensei:multipart_field_name': 'metadata_out'
                            }
                        }
                    }
                ]
            }
        }

        const formData = new FormData()
        formData.append('contentAnalyzerRequests', JSON.stringify(codePayload.contentAnalyzerRequests))

        const config = {
            file: curFile,
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_MLSENSEI}/predict`,
            method: 'POST',
            mode: 'cors',
            timeout: 90000,
            headers: getSenseiHeaders(),
            payload: codePayload,
            body: {
                formData: formData
            }
        }

        /* *********************************** */
        dispatch({ type: 'set-codes', payload: { config: config } })

        try {
            const output = await ssApiAsyncCall(config)
            const outputFileName = `adaptiveprofiles_${uuid()}_${curFile.shortName}.jpeg`
            const newFile = new File([output], outputFileName, { type: 'image/jpeg' })
            // 1. Explore the Index Document and get the Primary URL of the home Directory
            const homeDirectoryPrimaryUrl = await exploreIndexDocument()

            // 2. Explore the home Directory and get the Create URL of the Cloud Documents Directory
            const exploreHomeDirectoryResponse = await exploreHomeDirectory(
                homeDirectoryPrimaryUrl
            )
            const cloudDocumentsCreateUrl = exploreHomeDirectoryResponse.createLink
            const outputHref = `${getTempDirectory()}/${outputFileName}`
            const fileUploaded = await uploadFile(
                cloudDocumentsCreateUrl,
                newFile
            )
            const uploadlinks = LinkHeader.parse(fileUploaded.headers.get('link'))
            const primaryUrl = uploadlinks.refs.filter(val => val.rel.match('http://ns.adobe.com/adobecloud/rel/primary'))[0].uri
            const renditionUrl = uploadlinks.refs.filter(val => val.rel.match('http://ns.adobe.com/adobecloud/rel/rendition'))[0].uri
            const largeRendition = await retrieveRendition(
                renditionUrl,
                1024,
                'image/jpeg'
            )
            const preSignedDownloadUrl = await retrievePreSignedDownloadUrl(primaryUrl)
            const smallRendition = await retrieveRendition(
                renditionUrl,
                0,
                'image/jpeg'
            )
            // Skycity library to be udpated for Sensei response
            if (files.find(file => file.inputFile === curFile)) {
                return
            }
            files.push({
                inputFile: curFile,
                secondInput: curSecondFile,
                name: getFilename(outputHref),
                shortName: getFilenameWithoutExt(outputHref),
                path: outputHref,
                outputThumbnail: largeRendition,
                output: smallRendition,
                outputToDownload: preSignedDownloadUrl
            })

            dispatch({
                type: 'skycity-success',
                payload: {
                    files: files,
                    response: {},
                    loading: false,
                    wait: false
                }
            })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(inputFile => {
                inputSecondFiles.forEach(inputSecondFile => {
                    funcArray.push(start(inputFile, inputSecondFile))
                })
            })

            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    console.log(`err: ${err}`)
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                    dispatch({
                        type: 'sensei-end-wait'
                    })
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}

export async function ssApiAsyncCall (options) {
    const response = await fetch(options.url, {
        headers: {
            'X-Api-Key': `${process.env.REACT_APP_CONFIG_APP_KEY}`,
            Authorization: options.headers.Authorization,
            Prefer: 'respond-async, wait=60'
        },
        body: options.body.formData,
        method: 'post'
    })

    if (!response.ok) {
        console.log(response)
        throw new Error('Failed to call Async service')
    }

    const buffer = await response.arrayBuffer()
    const decoder = new TextDecoder()
    const text = decoder.decode(buffer);
    const boundaryIndex = text.indexOf("--Boundary_");
    if (boundaryIndex === -1) {
      throw new Error("Boundary not found in response.");
    }
    const multipartData = buffer.slice(boundaryIndex);
    const multipartText = decoder.decode(multipartData);
    const parts = multipartText.split("\r\n");

    let binaryStartIndex = null;

    for (let i = 0; i < parts.length; i++) {
      if (parts[i].includes("Content-Type: application/octet-stream")) {
        binaryStartIndex = multipartText.indexOf("\r\n\r\n", multipartText.indexOf(parts[i])) + 4;
        break;
      }
    }

    if (binaryStartIndex === null) {
      throw new Error("Image data not found.");
    }
    const imageData = multipartData.slice(binaryStartIndex);
    return imageData;
};
